import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { PageTitle, Layout } from "../components";
import Seo from "../components/Seo";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="横丁ブログ"
        description="東京横丁に関するのニュースやブログがご覧いただけます。"
        keywords="東京横丁,とうきょうよこちょう,TOKYO YOKOCHO,ニュース,NEWS,トピックス,TOPICS,ブログ,BLOG"
      />
      <section className="px-6 md:px-16 lg:px-24 xl:px-0 max-w-5xl mx-auto mb-24 md:mb-32 xl:mb-40">
        <PageTitle title="横丁ブログ" />
        <Tabs>
          <TabList>
            <Tab>ALL</Tab>
            <Tab>ブログ</Tab>
            <Tab>お知らせ</Tab>
            <Tab>メディア掲載</Tab>
          </TabList>

          {/* ブログ - 全表示 */}
          <TabPanel>
            <div className="gap-4 lg:gap-8 grid grid-cols-2 md:grid-cols-3 xl:gap-12 mb-16">
              {data.allMicrocmsBlog.edges.map((edge) => {
                const post = edge.node;
                const category = post.category[0].name;
                return (
                  <React.Fragment key={post.blogId}>
                    <Link
                      to={`/blog/${post.blogId}`}
                      className="w-full h-64 lg:h-80 xl:h-96 bg-white filter drop-shadow-lg rounded-2xl lg:rounded-blog group hover:drop-shadow-2xl transform duration-500"
                    >
                      <img
                        className="w-full h-1/2 rounded-t-2xl lg:rounded-blog-img object-cover"
                        src={post.featuredImage.url}
                        alt={`${post.title} 画像`}
                      />
                      <div className="pt-2 px-2 pb-2 lg:p-6">
                        <div className="w-full flex items-center justify-between mb-2">
                          <span
                            className={`${
                              category === "お知らせ"
                                ? "bg-primary-purple"
                                : `${
                                    category === "メディア掲載"
                                      ? "bg-primary-blue"
                                      : `${
                                          category === "ブログ"
                                            ? "bg-primary-orange"
                                            : ""
                                        }`
                                  }`
                            } rounded-full py-1 px-2 lg:px-4 text-2xs lg:text-xs text-white font-medium`}
                          >
                            {category}
                          </span>
                          <time
                            dateTime={post.createdAt}
                            className="text-2xs lg:text-xs tracking-wider text-gray-600 font-medium"
                          >
                            {post.date}
                          </time>
                        </div>
                        <h6 className="text-xs lg:text-sm xl:text-base font-medium tracking-wider leading-relaxed group-hover:text-blue-500 transform duration-300">
                          {post.title}
                        </h6>
                      </div>
                    </Link>
                  </React.Fragment>
                );
              })}
            </div>
          </TabPanel>
          {/* ブログ - お知らせ */}
          <TabPanel>
            <div className="gap-4 lg:gap-8 grid grid-cols-2 md:grid-cols-3 xl:gap-12 mb-16">
              {data.allMicrocmsBlog.edges.map((edge) => {
                const post = edge.node;
                const category = post.category[0].name;
                if (category === "ブログ") {
                  return (
                    <React.Fragment key={post.blogId}>
                      <Link
                        to={`/blog/${post.blogId}`}
                        className="w-full h-64 lg:h-80 xl:h-96 bg-white filter drop-shadow-lg rounded-2xl lg:rounded-blog group hover:drop-shadow-2xl transform duration-500"
                      >
                        <img
                          className="w-full h-1/2 rounded-t-2xl lg:rounded-blog-img object-cover"
                          src={post.featuredImage.url}
                          alt={`${post.title} 画像`}
                        />
                        <div className="pt-2 px-2 pb-2 lg:p-6">
                          <div className="w-full flex items-center justify-between mb-2">
                            <span className="bg-primary-orange rounded-full py-1 px-2 lg:px-4 text-2xs lg:text-xs text-white font-medium">
                              {category}
                            </span>
                            <time
                              dateTime={post.createdAt}
                              className="text-2xs lg:text-xs tracking-wider text-gray-600 font-medium"
                            >
                              {post.date}
                            </time>
                          </div>
                          <h6 className="text-xs lg:text-sm xl:text-base font-medium tracking-wider leading-relaxed group-hover:text-blue-500 transform duration-300">
                            {post.title}
                          </h6>
                        </div>
                      </Link>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="gap-4 lg:gap-8 grid grid-cols-2 md:grid-cols-3 xl:gap-12 mb-16">
              {data.allMicrocmsBlog.edges.map((edge) => {
                const post = edge.node;
                const category = post.category[0].name;
                if (category === "お知らせ") {
                  return (
                    <React.Fragment key={post.blogId}>
                      <Link
                        to={`/blog/${post.blogId}`}
                        className="w-full h-64 lg:h-80 xl:h-96 bg-white filter drop-shadow-lg rounded-2xl lg:rounded-blog group hover:drop-shadow-2xl transform duration-500"
                      >
                        <img
                          className="w-full h-1/2 rounded-t-2xl lg:rounded-blog-img object-cover"
                          src={post.featuredImage.url}
                          alt={`${post.title} 画像`}
                        />
                        <div className="pt-2 px-2 pb-2 lg:p-6">
                          <div className="w-full flex items-center justify-between mb-2">
                            <span className="bg-primary-purple rounded-full py-1 px-2 lg:px-4 text-2xs lg:text-xs text-white font-medium">
                              {category}
                            </span>
                            <time
                              dateTime={post.createdAt}
                              className="text-2xs lg:text-xs tracking-wider text-gray-600 font-medium"
                            >
                              {post.date}
                            </time>
                          </div>
                          <h6 className="text-xs lg:text-sm xl:text-base font-medium tracking-wider leading-relaxed group-hover:text-blue-500 transform duration-300">
                            {post.title}
                          </h6>
                        </div>
                      </Link>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </TabPanel>
          {/* ブログ - メディア掲載 */}
          <TabPanel>
            <div className="gap-4 lg:gap-8 grid grid-cols-2 md:grid-cols-3 xl:gap-12 mb-16">
              {data.allMicrocmsBlog.edges.map((edge) => {
                const post = edge.node;
                const category = post.category[0].name;
                if (category === "メディア掲載") {
                  return (
                    <React.Fragment key={post.blogId}>
                      <Link
                        to={`/blog/${post.blogId}`}
                        className="w-full h-64 lg:h-80 xl:h-96 bg-white filter drop-shadow-lg rounded-2xl lg:rounded-blog group hover:drop-shadow-2xl transform duration-500"
                      >
                        <img
                          className="w-full h-1/2 rounded-t-2xl lg:rounded-blog-img object-cover"
                          src={post.featuredImage.url}
                          alt={`${post.title} 画像`}
                        />
                        <div className="pt-2 px-2 pb-2 lg:p-6">
                          <div className="w-full flex items-center justify-between mb-2">
                            <span className="bg-primary-blue rounded-full py-1 px-2 lg:px-4 text-2xs lg:text-xs text-white font-medium">
                              {category}
                            </span>
                            <time
                              dateTime={post.createdAt}
                              className="text-2xs lg:text-xs tracking-wider text-gray-600 font-medium"
                            >
                              {post.date}
                            </time>
                          </div>
                          <h6 className="text-xs lg:text-sm xl:text-base font-medium tracking-wider leading-relaxed group-hover:text-blue-500 transform duration-300">
                            {post.title}
                          </h6>
                        </div>
                      </Link>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </TabPanel>
        </Tabs>
      </section>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  {
    allMicrocmsBlog(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          blogId
          date(formatString: "Y.MM.DD")
          featuredImage {
            url
          }
          title
          content
          category {
            id
            name
          }
        }
      }
    }
  }
`;
